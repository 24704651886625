export function getAppid() {
    let domain_map = {
        'mangabuz.com': 1,
        'mayonn.click': 2,
        'neopad.click': 3,
        'glodream.click': 4,
        'mospix.click': 5,
        'comicbuz.com': 99,
    };

    let hostname = window.location.hostname;
    // 获取根域名的函数
    function getRootDomain(hostname) {
        let parts = hostname.split('.');
        if (parts.length > 2) {
            parts = parts.slice(parts.length - 2);
        }
        return parts.join('.');
    }

    // 获取当前主机名的根域名
    let rootDomain = getRootDomain(hostname);

    // 如果根域名在 domain_map 中存在，则返回对应的 appid
    if (domain_map[rootDomain]) {
        return domain_map[rootDomain];
    }

    // 默认返回 'mangabuz.com' 的 appid
    return domain_map['mangabuz.com'];
}